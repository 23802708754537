<template>
  <table
    aria-describedby="Account connected credits card"
    class="w-full"
  >
    <tr>
      <th scope="col">Brand</th>
      <th scope="col">Expiration</th>
      <th scope="col">Number</th>
      <th scope="col">Action</th>
    </tr>
    <tr v-if="!loadingCards && cards.length === 0">
      <td colspan="4">No cards connected</td>
    </tr>
    <tr
      v-else
      v-for="card in cards"
      :key="card.stripe_id"
    >
      <td>{{ card.brand }}</td>
      <td>{{ card.exp_month }} / {{ card.exp_year }}</td>
      <td>**** **** **** {{ card.last4 }}</td>
      <td>
        <action-button
          @click="remove(card.stripe_id)"
          :disable="disableCardDeletion"
          type="delete"
        />
      </td>
    </tr>
    <tr v-if="deleteError">
      <td colspan="4">
        <pre>{{ deleteError }}</pre>
      </td>
    </tr>
    <tr v-if="cardsError">
      <td colspan="4">
        <pre>{{ cardsError }}</pre>
      </td>
    </tr>
    <tr v-if="loadingCards">
      <td colspan="4">
        <bb-loader />
      </td>
    </tr>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BbLoader from '@/components/loader/BBLoader'
import ActionButton from '@/components/btn/ActionButton'

export default {
  name: 'card-list',
  components: { ActionButton, BbLoader },
  data() {
    return {
      deleting: false,
      deleteError: null,
    }
  },
  computed: {
    ...mapGetters({
      cards: 'card/getCards',
      loadingCards: 'card/isCardsLoading',
      cardsError: 'card/getCardsError',
      isAdmin: 'auth/getIsAdmin',
    }),
    disableCardDeletion() {
      // We must have at least one card to charge
      if (this.isAdmin) {
        return
      } else if (this.cards.length < 2 && !this.isAdmin) {
        return this.cards.length < 2
      }
    },
  },
  methods: {
    ...mapActions({
      loadCardsIfNeeded: 'card/loadCardsIfNeeded',
      loadCards: 'card/loadCards',
    }),
    remove(stripeId) {
      if (this.loadingCards || this.deleting || this.disableCardDeletion) return

      this.deleting = false
      this.deleteError = null

      this.$http
        .delete(`card/${stripeId}`)
        .catch(e => (this.deleteError = e))
        .finally(() => {
          this.deleting = false
          this.loadCards()
        })
    },
  },
  beforeMount() {
    this.loadCardsIfNeeded()
  },
}
</script>

<style lang="scss" scoped>
th {
  text-align: start;
}

tr:nth-child(odd) {
  background-color: #f7f7f7;
}

th,
td {
  @apply py-4 pl-2 border border-bb-pale-grey;
}
</style>
