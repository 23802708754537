<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-card :size="32" />
        </template>
        <template #default> Connected cards </template>
      </page-title>
    </page-section>

    <page-section bottom-border>
      <card-list />
    </page-section>

    <page-section bottom-border>
      <the-button
        @click="showCardModal"
        :disabled="loadingCards"
        primary
      >
        Add credit card
      </the-button>
    </page-section>
  </page>
</template>

<script>
import IcCard from 'vue-material-design-icons/CreditCard'
import CardList from '@/components/account/CardList'
import CardModal from '@/components/modals/CardModal'
import { mapGetters } from 'vuex'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'

export default {
  name: 'Cards',
  components: { PageSection, Page, IcCard, CardList },
  computed: {
    ...mapGetters({
      loadingCards: 'card/isCardsLoading',
    }),
  },
  methods: {
    showCardModal() {
      this.$modal.show(CardModal, { parent: 'billing' }, CardModal.modalProps)
    },
  },
}
</script>

<style scoped></style>
