<template>
  <div
    @click="click"
    class="link-primary flex items-center"
    :class="{ disable, 'text-xs': small }"
  >
    <component
      :is="type"
      :size="small ? 12 : 16"
      class="ml-2 mr-1"
    />
    <span class="capitalize">{{ type }}</span>
  </div>
</template>

<script>
import Manage from 'vue-material-design-icons/Cog'
import Delete from 'vue-material-design-icons/DeleteForever'
import Download from 'vue-material-design-icons/CloudDownload'

export default {
  name: 'action-button',
  components: { Manage, Delete, Download },
  props: {
    type: {
      type: String,
      validator: val => ['manage', 'delete', 'download'].includes(val),
      required: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    click(e) {
      if (!this.disable) this.$emit('click', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.disable {
  color: $bb-mid-grey !important;
  cursor: default;
}
</style>
